export default {
    baseUrl: 'https://apiv2.lietoudaohang.com/service/admin',
    crudbaseUrl: 'https://apiv2.lietoudaohang.com',
    // baseUrl: 'https://fywtat.mynatapp.cc/service/admin',
    // crudbaseUrl: 'https://fywtat.mynatapp.cc',
    login: '/login', //登录
    foreignlanguagecategorylist:'/foreignlanguagecategorylist',//外语种类列表
    add_foreignlanguagecategory:'/add_foreignlanguagecategory',//添加外语种类
    edit_foreignlanguagecategory:"/edit_foreignlanguagecategory",//修改外语种类
    del_foreignlanguagecategory:'/del_foreignlanguagecategory',//删除外语种类

    industrylist:"/industrylist",//行业分类列表
    citylist:"/citylist",//行业分类列表
    add_industry:'/add_industry',//添加行业分类
    add_city:'/add_city',//添加行业分类
    edit_industry:"/edit_industry",//修改行业分类
    edit_city:"/edit_city",//修改行业分类
    del_industry:'/del_industry',//删除行业分类
    del_city:'/del_city',//删除行业分类

    industry_zhilianlist:"/industry_zhilianlist",//行业分类(智联)列表
    add_industry_zhilian:"/add_industry_zhilian",//添加行业分类(智联)
    edit_industry_zhilian:"/edit_industry_zhilian",//修改行业分类(智联)
    del_industry_zhilian:"/del_industry_zhilian",//删除行业分类(智联)
    industry_zhilian_tree:"/industry_zhilian_tree",//智联树，所有智联列表
    city_zhilian_tree:"/city_zhilian_tree",//智联树，所有智联列表

    jobcategorylist:"/jobcategorylist",//岗位分类列表
    add_jobcategory:'/add_jobcategory',//添加岗位分类
    edit_jobcategory:'/edit_jobcategory',//修改岗位分类
    del_jobcategory:'/del_jobcategory',//删除岗位分类
    swiperlist:'/swiperlist',//广告列表
    add_swiper:"/add_swiper",//添加广告
    aliyun_osssts:"/aliyun_osssts",//阿里云oss ststoken
    edit_swiper:"/edit_swiper",//修改广告
    del_swiper:"/del_swiper",//删除广告
    com_team:'/com_team',//猎企成员
    compaylist:"/compaylist",//猎企列表
    compayexaminelist:"/compayexaminelist",//猎企审核列表
    compayexamineoperation:"/compayexamineoperation",//操作猎企审核
    compayview:"/compayview",//猎企详情
    compostlist:"/compostlist",//猎企岗位列表
    compostoperation:'/compostoperation',//猎企岗位下架操作
    compostcandidatelist:"/compostcandidatelist",//猎企岗位应聘人员
    memberlist:"/memberlist",//用户列表
    del_member:'/del_member',//拉黑用户
    app_logo:"/app_logo",//猎头端logo详情
    foreignlang_keyword:"/foreignlang_keyword",//外语能力关键词详情
    edupeizhi_viewmax:"edupeizhi_viewmax",//猎聘/智联查看额度详情
    post_rank:'/post_rank',//岗位职级后缀关键词详情
    vocabulary:"/vocabulary",//严禁敏感词汇详情
    com_enterpriseagreement:"/com_enterpriseagreement",//猎头端用户协议详情
    com_jobsagreement:"/com_jobsagreement",//求职端用户协议详情
    problemlist:"/problemlist",//问题列表
    add_problem:'/add_problem',//添加问题
    del_problem:'/del_problem',//删除问题
    edit_problem:'/edit_problem',//修改问题
    compostnavigation_view:'/compostnavigation_view',//查看导航
    problem_positionlist:'/problem_positionlist',//岗位列表
    modify_userpass:'/modify_userpass',
    modellist:'/modellist', //模型数据
    modelresultlist:'/modelresultlist',
    add_modelresult:'/add_modelresult',
    del_modelresult:'/del_modelresult',
    edit_modelresult:'/edit_modelresult',
    modellistlist:"modellistlist",//列表模型数据
    zuhelist:'/zuhelist',//组合库列表

    modelcheck:'/modelcheck',
    modellistcheck:'modellistcheck',//列表模型校验
    gslist:'/gslist',
    gslist0:'/gslist0',
    gsindustrys:'/gsindustrys',
    add_gs:'/add_gs',
    del_gs:'/del_gs',
    del_gs2:'/del_gs2',
    memorytable:'/memorytable',
    edit_gs:'/edit_gs',
    import_excel:'/import_excel',
    // import_excel_zuhe:'import_excel_zuhe',
    user_candidateview:'/user_candidateview',
    edit_user_joincompany:'/edit_user_joincompany',

    crudselectonebyid:'/crud/selectOneById',//通用操作数据库接口->crud
    crudselectListByMap:'/crud/selectListByMap',
    crudupdatebyid:'/crud/updateById',
    crudInsert:'/crud/insert',

    industrycity_zhilianlist:'/industrycity_zhilianlist',
    del_industrycity_zhilian:'/del_industrycity_zhilian',
    industrycity_list:'industrycity_list',
    del_industrycity:'/del_industrycity',

    upload:'/upload',

    hirebotuser_add:'/hirebotuser/add',
    hirebotuser_update:'/hirebotuser/update',
    hirebotuser_resetpass:'/hirebotuser/resetpass',
    hirebotuser_list:'/hirebotuser/list',

}
